/* Header */
#header-wrap {
    border-bottom: #0B4A82 solid 4px;
    height: 80px;
    background: #105998;

    padding-left: 10px;
    box-shadow: 0px 2px 2px #333;
}

.primaryBlue {
    color: #105998;
}

.secondaryPink {
    color: #E18F81;
}

#header h1 {
    color: #fff;
    -font-family: 'Cantata One', serif;
    font-family: 'Arial', sans-serif;
    font-size: 4em;
}

/*
#loginView {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 30em;
    height: 18em;
    margin-top: -9em;
    margin-left: -15em;
    border: 1px solid #4E4848;
    background-color: #f3f3f3;
    box-shadow: black 0px 2px 20px;
    display: none;
}
*/

#loginView {
    margin-top: 50px;
}

#loginForm {
    display: none;
}

#loginError {
    margin: 10px;
    margin-bottom: 20px;
}

#system-error {
    background-color:#D97E7A;
    margin:10px auto 10px auto;
    border-radius:10px;
    width:70%;
    padding:10px;
    border:3px solid #D97E7A;
    display: none;
}

.helpButton {
    cursor: pointer;
    text-decoration: none;
}

#userHeader {
    display: none;
    background: #666;
    color: #fff;
    padding: 10px 15px 10px 15px;
    box-shadow: 0px 2px 2px #333;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 25px;
}

#userHeader select {
    color: black;
}

#userHeader a {
    color: white;
}

#userHeader a:hover {
    text-shadow: 0px 0px 4px #eee !important;
}

#userHeader .topAction {
    float: right;
    margin-right: 8px;
}

#userHeader .right {
    float: right;
}

.nonModalDialog {
    display: none;
    width: 60%;
    margin: 0 auto;
    padding: 10px;
    background: #333;
    color: white;
    border: 1px solid black;
    border-radius: 10px;
}

.nonModalDialog input {
    color: black;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
}

.error {
    border: 1px solid red !important;
}

.errors {
    color: red;
    background-color: #fff;
    border-radius: 5px;
    font-size: 110%;
    margin: 0px;
    list-style-type: none;
}

.errors li:first-child {
    padding-top: 6px;
}

.errors li:last-child {
    padding-bottom: 6px;
}

.errors li {
    padding: 2px;
    padding-left: 8px;
    margin: 2px !important;
}

.modalDialog {
    position: fixed;
    font-family: Arial, Helvetica, sans-serif;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    opacity:0;
    -webkit-transition: opacity 400ms ease-in;
    -moz-transition: opacity 400ms ease-in;
    transition: opacity 400ms ease-in;
    pointer-events: none;
}
.modalDialog:target {
    opacity:1;
    pointer-events: auto;
}
.modalDialog > div {
    width: 400px;
    position: relative;
    margin: 30% auto;
    padding: 5px 20px 13px 20px;
    border-radius: 10px;
    background: #fff;
    background: -moz-linear-gradient(#fff, #999);
    background: -webkit-linear-gradient(#fff, #999);
    background: -o-linear-gradient(#fff, #999);
}
.close {
    background: #606061;
    color: #FFFFFF;
    line-height: 25px;
    position: absolute;
    right: -12px;
    text-align: center;
    top: -10px;
    width: 24px;
    text-decoration: none;
    font-weight: bold;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    -moz-box-shadow: 1px 1px 3px #000;
    -webkit-box-shadow: 1px 1px 3px #000;
    box-shadow: 1px 1px 3px #000;
}
.close:hover {
    background: #00d9ff;
}

/* new modal: */
/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
    z-index: 2;
    position: relative;
    background: #333;
    color: white;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border: 1px solid black;
    border-radius: 10px;
    width: 65%; /* Could be more or less, depending on screen size */
}

.modal-content-centered {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    color: black;
    padding: 20px 20px 0 20px;
    border: 2px solid black;
    border-radius: 10px;
    width: 65%;
}

.modal-content input {
    color: black;
}

.button-small {
    width: 2.5em;
    height: 2.5em;
}

.button-primary {
    background-color: #0b4a82 !important;
    border-color: #0b4a82 !important;
}

.button-secondary {
    background-color: #278ae2 !important;
}

.button-disabled {
    background-color: #ddd !important;
    border-color: gray !important;
    font-style: italic;
}

button {
    cursor: pointer;
}

button:disabled {
    color: #222;
    background-color: #ddd !important;
    border-color: gray !important;
    font-style: italic;
}

select:disabled {
    background: lightgray;
}

.timesheet {
    border-radius: 5px;
    border: 1px solid #aaa;
}

.timesheet .editing .view {
    display: none;
}

.btnWrap {
    display: flex;
    justify-content: flex-end;
}

.timesheet .editing .edit {
    display: block;
}

.entry:not(.editing) .editExtra {
    display: none !important;
}

.entry:not(.editing) .select2-container {
    display: none !important;
}

.timesheet.editing .editExtra {
    display: block;
}

.timesheet.rejected:not(.editing) .responses {
    display: none !important;
}

.timesheet.group_rejected:not(.editing) .responses {
    display: none !important;
}

.timesheet.review:not(.editing) .responses {
    display: none !important;
}

.timesheet:not(.rejected):not(.group_rejected) .responses {
    display: none !important;
}

.timesheet:not(.rejected):not(.review):not(.group_rejected) .responses {
    display: none !important;
}


.responses {
    background-color: #D97E7A;
    padding: 8px !important;
    border: 1px solid #666;
    border-radius: 5px;
    margin-top: 10px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.responses ul {
    margin: 1rem;
    list-style-type: none;
}

.notesLabel {
    height: 6.5em;
    width: 62.5em;
}

li.timesheet.submitted .entry button {
    display: none;
}
li.timesheet.recall_requested .entry button {
    display: none;
}

li.timesheet.approved .entry button {
    display: none;
}

li.timesheet.lapsed .entry button {
    display: none;
}

li.timesheet:not(.editing) .errors {
    display: none;
}

.timesheet .entry:first-child label.edit {
    display: block;
}

.timesheet .entry:first-child div.edit {
    display: block;
}

/* Display field names on entry views for mobile screens */
@media only screen and (max-width: 550px) {
    .timesheet .entry div.edit {
        display: block;
    }
    .timesheet .entry label.edit {
        display: block;
    }
}

.timesheet .edit {
    display: none;
}

.timesheet .editExtra {
    display: none;
    font-size: 0.8em;
}

.timesheet ul.entries,
.timesheet ul.entryGroups,
.timesheet div.noTimeWorked {
    display: none;
}

.timesheet .comments {
    display: none;
    margin-left: 10px;
    width: calc(100% - 20px);
}

.comments textarea {
    width: 100%;
}

textarea.comments {
    color: black;
    width: 100%;
}

.timesheet .submitButton {
    display: none;
    margin-left: 10px;
}

.timesheet .printButton {
    display: none;
    margin-left: 10px;
}

.timesheet.view ul.entries,
.timesheet.view ul.entryGroups {
    display: block;
}

.groupTitle {
    line-height: 0.5;
    text-align: center;
    border-bottom: 1px solid black;
}

.groupTitle span {
    display: inline-block;
    position: relative;
}

.groupTitle span:before,
.groupTitle span:after {
    content: "";
    position: absolute;
    height: 5px;
    top: 0;
    width: inherit;
}

.groupTitle span:before {
    right: 100%;
    margin-right: 15px;
}

.groupTitle span:after {
    left: 100%;
    margin-left: 15px;
}

fieldset.title {
    border-top: 1px solid #aaa;
    border-bottom: none;
    border-left: none;
    border-right: none;
    display: block;
    text-align: left;
}

fieldset.title legend {
    padding: 5px 10px;
}

fieldset.title legend:nth-child(1) {
    margin-left: 30px;
}

fieldset.title legend:nth-child(2) {
    margin-left: 80px;
}

fieldset.buttons {
    text-align: center;
}

li.timesheet:not(.closed) {
    background: #eee;
    border: none;
    box-shadow: 0px 2px 5px #222;
    margin-top: 2rem !important;
}

li.timesheet:first-child {
    margin-top: 0.5rem !important;
}

div.group-payperiod-container {
    background: #eee;
    border: none;
    box-shadow: 0px 2px 5px #222;
    margin-top: 2rem !important;
    margin-bottom: 10px;
}


#timesheet-list {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

#timesheet-list li {
    margin-top: 0rem;
    margin-bottom: 1.25rem;
}

#timesheet-list li .row {
    padding: 2px;
    margin: 2px;
}

ul.entries,
ul.entryGroups {
    list-style-type: none;
    padding:4px;
    margin: 0px;
    margin-top: 0.5rem;
}

.flash {
    background-color: #ccc;
    padding: 5px;
    border: 1px solid #bbb;
    border-radius: 5px;
    margin-bottom: 4px !important;
    display: none;
}

.entry {
    background-color: #ccc;
    padding: 5px;
    border: 1px solid #bbb;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    border-bottom: 1px solid #666;
    border-radius: 5px;
    margin-bottom: 4px !important;
}

.entry:nth-child(even) {
    background-color: #aaa;
}

.entry:nth-child(even) label.view {
    border: 1px solid #999;
}

.entry.editing {
    background-color: #93C4F0;
    border: 1px solid #288AE2;
    border-bottom: 1px solid #105898;
}

label.view {
    font-size: 0.9em;
    color: #333;
    background: #eee;
    border: 1px solid #bbb;
    border-radius: 5px;
    padding: 2px 8px 2px 8px;
}

label.status {
    font-size: 0.75em;
    text-transform: capitalize;
    display: block;
    position: relative;
    float: left;
    left: -20px;
    top: -10px;
    width: 72px;
    color: black;
    background-color: #aaa;
    padding: 5px 5px 5px 10px;
    transform: rotate(-10deg);
    box-shadow: 2px 2px 5px #222;
    border-radius: 3px;
    margin: 0px !important;
}

li.closed .timesheet-header {
    font-style: italic;
}

li.closed .timesheet-header:hover,
li.closed .timesheet-header:active,
li.closed .timesheet-header:focus {
    background-color: #ddd !important;
    text-shadow: 0px 0px 4px #eee !important;
}

li.closed .payperiod-header {
    font-style: italic;
}

li.closed .payperiod-header:hover,
li.closed .payperiod-header:active,
li.closed .payperiod-header:focus {
    background-color: #ddd !important;
    text-shadow: 0px 0px 4px #eee !important;
}

li.closed label.status {
    top: 2px;
    transform: none;
    font-style: normal;
}

label.status.pending {
    background-color: #666;
    color: white;
}

label.status.open {
    background-color: #333;
    color: white;
}

label.status.rejected, label.status.group_rejected {
    background-color: #D97E7A;
}

label.status.approved {
    background-color: #82E181;
}

label.status.submitted {
    background-color: #93C4F0;
}

label.status.recall_requested {
    background-color: #93C4F0;
}

.payperiod-header label:not(.status) {
    display: inline;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-shadow: 0px 2px 8px #999;
}

.timesheet-header label:not(.status) {
    display: inline;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-shadow: 0px 2px 8px #999;
}

#timesheet-list li .timesheet-header {
    background-color: #ccc;
/*
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: inherit;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: inherit;
*/
    border: 1px solid #bbb;
    border-bottom: 1px solid #666;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0px;
    padding: 0px;
}

#group-admin .payperiod-header {
    background-color: #ccc;
    /*
        border-top-style: solid;
        border-top-width: 1px;
        border-top-color: inherit;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: inherit;
    */
    border: 1px solid #bbb;
    border-bottom: 1px solid #666;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-top: 20px;
    padding: 0px;
}


#ledger {
    /* margin-top: 8px; */
}

li .groupHeader {
	background-color: #bbb;
	border: 1px solid #aaa;
    border-bottom: 1px solid #666;
    margin: 0px;
    padding: 4px;
    padding-left: 10px;
    /* border-radius: 5px; */
    box-shadow: 2px 2px 2px #333;
    position: relative;
    left: -8%;
    width: 106%;
	color: black;
	font-style: italic;
	font-weight: bold;
}

li ul{
	list-style:none;
	margin-top:10px;
	margin-left: 0;
}

.timesheet .entry:first-child label.edit{
	display:none !important;
}

/* TODO: Add color styles for status labels */
.recallButton {
    display: none;

}
.timesheet.view .recallButton {
    display: inline-block;
    margin-left: 10px;
}
.payperiod-timesheets table{
    margin-left: 20px;
    margin-bottom: 5px;
}

.payperiod-timesheets th, .payperiod-timesheets td {
    padding: 6px 6px;
    border-bottom: none;
}

#group-admin .submitButton {
    margin-left: 10px;
}

/* styles added to customize select2 */
.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-results__option[aria-selected=true] {
	display:none !important;
}

.select2-container--default .select2-selection--single {
    color: #000 !important;
    height: 38px !important;
    line-height: 38px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #000 !important;
    height: 38px !important;
    line-height: 38px !important;
}

.select2-container--default {
    color: #000 !important;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    color: #000 !important;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #000 !important;
}

.select2-results__option {
    font-size: small !important;
}
